<template>
  <div>
    <v-row class="mx-auto" title="Best Charcoal, contact uocharcoal">
      <v-col cols="12" sm="6"   order="3" order-sm="1">
        <div class="contact">
          <h2>
          Contact Us:
        </h2>
        <br>
        <contact-details></contact-details>
        <social-site></social-site>
        </div>
      </v-col>
        
      <v-col cols="12" sm="6" order="2">
        <v-card elevation="6">
          <v-container>
            <v-form v-if="showForm">
              <v-text-field
                v-model="form.name"
                label="Your Name"
                title="Uocharcoal need your name"
                :color="themeColor"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                label="Your E-mail"
                title="Uocharcoal need your email"
                :color="themeColor"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.phoneno"
                label="Your Phone Number"
                title="Uocharcoal need your phone number"
                :color="themeColor"
                required
              ></v-text-field>
              <v-text-field
                v-model="form.subject"
                label="Subject"
                title="Uocharcoal need to know the subject of your message "
                :color="themeColor"
                required
              ></v-text-field>
              <v-textarea
                v-model="form.message"
                rows="3"
                auto-grow
                :color="themeColor"
                label="Message"
                title="Uocharcoal need your message to serve you better"
                required
              ></v-textarea>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn :to="{name: 'home'}" color="error">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="submit" :disabled="disableSubmit" :loading="disableSubmit" dark>Submit</v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar :timeout="5000" v-model="snackbar" top :color="snackType">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import ContactDetails from './ContactDetails.vue'
import SocialSite from './SocialSite.vue'
import { apiClient } from "@/services"

export default {
  components: { SocialSite, ContactDetails },
  name: "ContactPage",
  data: () => ({
    themeColor: "#ff5722",
    form: {},
    showForm: true,
    disableSubmit: false,
    snackbar: false,
    snackMessage: '',
    snackType: '',
  }),
  methods: {
    setAlert(message, type){
      this.snackbar = true;
      this.snackMessage = message;
      this.snackType = type;
    },
    submit(){
      this.disableSubmit = true;
      apiClient
        .post('/contact.php', this.form)
        .then(res => {
          console.log(res.data)
          if (res.data.ok) {
            this.showForm = false;
            this.setAlert('Thank you. We will get back to you. You will be redirected soon.','success')
            setTimeout(()=> this.$router.push('/'), 5000)
          } else {
            this.disableSubmit = false;
            this.setAlert(res.data, 'error')
          }
        })
        .catch(err => {
          this.disableSubmit = false;
          this.setAlert(err.message, 'error')
        });
    }
  }
}
</script>

<style>
  .contact {
    border: 3px solid #ff5722;
    margin: 25px;
    padding: 10px;
    text-align: center;
  }
</style>